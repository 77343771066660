const scrolling = {
  overflowsWidth(el) {
    return !!el && el.offsetWidth < this.scrollSize(el, true)
  },

  overflowsHeight(el) {
    return el && el.offsetHeight < this.scrollSize(el)
  },

  canScrollLeft(el) {
    return this.overflowsWidth(el) && el.scrollLeft > 0
  },

  canScrollRight(el) {
    return el && el.scrollLeft < this.scrollSize(el, true) - el.offsetWidth
  },

  canScrollUp(el) {
    return this.overflowsHeight(el) && el.scrollTop > 0
  },

  canScrollDown(el) {
    return !!el && el.scrollTop < this.scrollSize(el) - el.offsetHeight
  },

  scrollSize(el, width = false) {
    // To calculate overflow and scrolling, we need the scroll[Width|Height], which does not include borders
    const style = getComputedStyle(el)
    const [base, dimA, dimB] = width ? [el.scrollWidth, 'left', 'right'] : [el.scrollHeight, 'top', 'bottom']
    const [borderA, borderB] = [dimA, dimB].map((dim) => parseInt(style.getPropertyValue(`border-${dim}-width`), 10))
    return base + borderA + borderB
  },
}

function getScrolledParent(element) {
  if (element.scrollTop !== 0) {
    return element
  } else if (!element.parentNode || element === document.body) {
    return null
  } else {
    return getScrolledParent(element.parentNode)
  }
}

export function getScrollableParent(element) {
  while (element) {
    const style = window.getComputedStyle(element)
    if (element.scrollTop !== 0 || ['auto', 'scroll'].includes(style.overflowY)) {
      return element
    }
    element = element.parentElement
  }

  return document.documentElement
}

// only works if scrolling isn't nested; please don't nest scrolling!
export function scrollIntoViewWithOffset(element, offset) {
  const scrollParent = getScrolledParent(element)
  if (scrollParent === null) {
    window.scrollTo({
      behavior: 'smooth',
      top: element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
    })
  } else {
    element.scrollIntoView()
    scrollParent.scrollTop = scrollParent.scrollTop - offset
  }
}

export default scrolling
