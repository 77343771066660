<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/mouse-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <component
    :is="tag"
    data-test="popover-root"
    aria-haspopup="true"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
  >
    <Portal :to="portalName">
      <transition
        v-bind="{
          name: transition,
          enterFromClass: enterClass,
          enterToClass,
          enterActiveClass,
          leaveActiveClass,
          leaveFromClass: leaveClass,
          leaveToClass,
          duration: transitionDuration,
        }"
      >
        <div
          v-if="!disabled && visible"
          :ref="setPopoverEl"
          role="tooltip"
          class="border-base absolute z-50 rounded-xl border border-solid shadow-xl"
          :class="[popoverClasses, `${invertedColors ? 'bg-primary-600' : 'bg-white'}`]"
          :style="popoverStyles"
          :data-popover-placement="currentPlacement"
          data-test="content"
          @mouseenter="$emit('content-mouseenter', $event)"
          @mouseleave="$emit('content-mouseleave', $event)"
        >
          <slot name="popover">{{ content }}</slot>
          <div
            :ref="setArrowEl"
            :class="`${invertedColors ? 'inverted ' : ''} arrow`"
            data-test="arrow"
            :style="arrowStyle"
          ></div>
        </div>
      </transition>
    </Portal>

    <component
      :is="referenceTag"
      v-if="!target"
      ref="defaultReference"
      data-test="default-reference"
      tabindex="0"
      :class="targetClass"
      :aria-describedby="popupId"
      @click="$emit('click')"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
      <slot />
    </component>
  </component>
</template>

<script>
import { ref, watch, computed, onMounted, onUnmounted } from 'vue'
import { computePosition, autoUpdate, offset, flip, shift, arrow } from '@floating-ui/dom'
import { isNumeric } from 'utils'
import flatMap from 'lodash/flatMap'

export const POSITIONS = flatMap(['top', 'right', 'bottom', 'left'], (pos) => [pos, `${pos}-start`, `${pos}-end`])
export const PADDINGS = ['sm', 'lg']
export const STRATEGIES = ['absolute', 'fixed']
export const DEFAULT_PORTAL = 'popovers'

export default {
  name: 'Popover',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    popupId: {
      type: String,
      default: '',
    },
    // For an explanation of transition props specific to Vue 2, see https://vuejs.org/v2/guide/transitions.html
    transition: {
      type: String,
      default: '',
    },
    transitionDuration: {
      type: Number,
      default: 0,
    },
    enterClass: {
      type: String,
      default: '',
    },
    enterToClass: {
      type: String,
      default: '',
    },
    enterActiveClass: {
      type: String,
      default: '',
    },
    leaveActiveClass: {
      type: String,
      default: '',
    },
    leaveClass: {
      type: String,
      default: '',
    },
    leaveToClass: {
      type: String,
      default: '',
    },
    popoverClass: {
      type: [String, Array, Object],
      default: '',
    },
    targetClass: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottom',
      validator: (val) => POSITIONS.includes(val),
    },
    padding: {
      type: String,
      default: 'lg',
      validator: (val) => PADDINGS.includes(val),
    },
    strategy: {
      type: String,
      default: 'absolute',
      validator: (val) => STRATEGIES.includes(val),
    },
    visible: {
      type: Boolean,
      default: false,
    },
    offset: {
      default: 8,
      validator: (val) => isNumeric(val),
    },
    target: {
      default: '',
      validator: (val) =>
        typeof val === 'string' || val instanceof HTMLElement || (val && val.constructor.name === 'VueComponent'),
    },
    arrowSize: {
      default: 12,
      validator: (val) => isNumeric(val),
    },
    referenceTag: {
      type: String,
      default: 'button',
    },
    portalName: {
      type: String,
      default: DEFAULT_PORTAL,
    },
    invertedColors: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const defaultReference = ref(null)
    const popoverEl = ref(null)
    const targetEl = ref(null)
    const arrowEl = ref(null)
    const currentPlacement = ref(props.placement)
    const cleanupWatchers = ref(null)
    const popoverStyles = ref({})
    const arrowStyle = ref({})

    const placementSide = computed(() => currentPlacement.value.match(/([^-]+)-?/)[1])
    const popoverClasses = computed(() => {
      const classes = [props.padding === 'sm' ? 'p-2' : 'p-4']
      if (props.popoverClass) classes.push(props.popoverClass)
      return classes
    })

    function trackPopoverPosition(target, popover) {
      stopTrackingPopoverPosition()
      cleanupWatchers.value = autoUpdate(target, popover, () => computePopoverPosition(target, popover))
    }

    function stopTrackingPopoverPosition() {
      if (cleanupWatchers.value) {
        cleanupWatchers.value.call()
      }

      cleanupWatchers.value = null
    }

    async function computePopoverPosition(target, popover) {
      const { x, y, placement, middlewareData } = await computePosition(target, popover, {
        placement: props.placement,
        strategy: props.strategy,
        middleware: [
          offset(Math.max(props.offset, props.arrowSize)),
          flip({ fallbackAxisSideDirection: 'start' }),
          shift({ padding: 10 }),
          arrow({ element: arrowEl.value, padding: 6 }),
        ],
      })

      currentPlacement.value = placement

      popoverStyles.value = {
        left: `${x}px`,
        top: `${y}px`,
      }

      if (middlewareData.arrow) {
        const { x: arrowX, y: arrowY } = middlewareData.arrow

        const staticSide = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right',
        }[placement.split('-')[0]]

        arrowStyle.value = {
          '--arrow-size': `${props.arrowSize}px`,
          '--arrow-rotation': `var(--${placementSide.value}-rotation)`,
          left: arrowX != null ? `${arrowX}px` : '',
          top: arrowY != null ? `${arrowY}px` : '',
          [staticSide]: `var(--arrow-offset)`,
        }
      }
    }

    function setTarget() {
      if (!props.target) {
        targetEl.value = defaultReference.value
      } else if (typeof props.target === 'string') {
        targetEl.value = document.querySelector(props.target)
      } else if (props.target instanceof HTMLElement) {
        targetEl.value = props.target
      } else if (props.target.constructor.name == 'VueComponent') {
        targetEl.value = props.target.$el
      } else {
        targetEl.value = null
      }
    }

    // We're using `setPopoverEl` and `setArrowEl` to dynamically set the template
    // ref values for `popoverEl` and `arrowEl`. Using just `ref="popverEl"` and
    // `ref="arrowEl"` wasn't updating correctly when toggling the visibility of
    // the popover portal content. This may not be needed once we switch over
    // to Vue 3, but I'm not totally sure.
    function setPopoverEl(el) {
      popoverEl.value = el
    }

    function setArrowEl(el) {
      arrowEl.value = el
    }

    function onUpdate(target, popover, visible) {
      if (target && popover && visible) {
        trackPopoverPosition(target, popover)
      } else {
        stopTrackingPopoverPosition()
      }
    }

    onMounted(setTarget)

    onUnmounted(() => {
      stopTrackingPopoverPosition()
    })

    watch(() => props.target, setTarget)

    watch([targetEl, popoverEl, () => props.visible], ([target, popover, visible]) => {
      onUpdate(target, popover, visible)
    })

    return {
      defaultReference,
      popoverEl,
      targetEl,
      arrowEl,
      currentPlacement,
      cleanupWatchers,
      popoverStyles,
      arrowStyle,
      placementSide,
      popoverClasses,
      trackPopoverPosition,
      stopTrackingPopoverPosition,
      computePopoverPosition,
      setPopoverEl,
      setTarget,
      setArrowEl,
      onUpdate,
    }
  },
}
</script>

<style scoped>
[data-popover-placement] {
  --top-rotation: 45deg;
  --bottom-rotation: -135deg;
  --left-rotation: -45deg;
  --right-rotation: 135deg;
  --arrow-size: 0.5rem;
}

[data-popover-placement] .arrow,
[data-popover-placement] .arrow::before {
  --arrow-offset: calc(var(--arrow-size) / -2);
  position: absolute;
  width: var(--arrow-size);
  height: var(--arrow-size);
}

.arrow::before {
  @apply border-base rounded-br-sm bg-white;
  visibility: visible;
  content: '';
  transform: rotate(var(--arrow-rotation));
  border: 1px solid rgba(186, 201, 255, 1);
  border-top: 0;
  border-left: 0;
}

.arrow.inverted::before {
  @apply border-base bg-primary-600 rounded-br-sm;
  visibility: visible;
  content: '';
  transform: rotate(var(--arrow-rotation));
  border: 1px solid rgba(186, 201, 255, 1);
  border-top: 0;
  border-left: 0;
}
</style>
