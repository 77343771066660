import { DEPRECATED_RESOURCE_TYPES } from 'rails_constants'
import { computed, unref } from 'vue'
import { isResourceOfType } from './use_resource_types'

function cardContent(card) {
  return unref(card)?.content
}

export function cardContentType(card) {
  return cardContent(card)?.type
}

function isCardOfType(card, type) {
  if (['TitleCard', 'ResourceCard'].includes(type) && unref(card)?.type === type) return true
  return isResourceOfType(cardContent(card), type)
}

export function isQuestionCard(card) {
  return isCardOfType(card, 'LearnosityItem')
}

export function isTitleCard(card) {
  return isCardOfType(card, 'TitleCard')
}

export function isSlideCard(card) {
  return isCardOfType(card, 'Slide')
}

export function isResourceCard(card) {
  return isCardOfType(card, 'ResourceCard')
}

export function isWikiCard(card) {
  return isCardOfType(card, 'Wiki')
}

export function isGeogebraCard(card) {
  return isCardOfType(card, 'GeogebraItem')
}

export function isHtmlCard(card) {
  return isCardOfType(card, 'Html')
}

export function isDocumentCard(card) {
  return isCardOfType(card, 'Document')
}

export function isVideoCard(card) {
  return isCardOfType(card, 'Video')
}

export function isHyperlinkCard(card) {
  return isCardOfType(card, 'Hyperlink')
}

export function isImageCard(card) {
  return isCardOfType(card, 'Image')
}

export function isAudioCard(card) {
  return isCardOfType(card, 'Audio')
}

export function hasPersistedResource(card) {
  return isResourceCard(card) && !!cardContent(card)?.id
}

export function isDeprecatedResourceTypeCard(card) {
  const content = cardContentType(card)
  return DEPRECATED_RESOURCE_TYPES.includes(content)
}

export function useCardTypes(cardRef) {
  return {
    isQuestionCard: computed(() => isQuestionCard(cardRef)),
    isTitleCard: computed(() => isTitleCard(cardRef)),
    contentType: computed(() => cardContentType(cardRef)),
    isDeprecatedResourceTypeCard: computed(() => isDeprecatedResourceTypeCard(cardRef)),
  }
}
